import FullWidthLayout from "../../layouts/full_width_layout";
import { useEffect, useState } from "react";
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from "../../store";
import NoAuthFullWidthLayout from "../../layouts/no_auth_full_width_layout";

const Fiorella: React.FC<PropsFromRedux> = ({}: PropsFromRedux) => {

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const onInit = async () => {
      try {
        
      } catch (error) {
          console.error('Error fetching data: ', error);
      } finally {
          setLoading(false);
      }
    };
    
    onInit();
  }, []);



  return (
    

    <div className="w-full py-4 bg-green-100 px-2 md:px-5">
        <div className="w-full text-center pb-12">
            <h1 className="mx-auto text-2xl font-bold text-green-800">Fiorella</h1>
        </div>
        <div className="w-full text-justify md:w-1/2 mx-auto">
            <p className="pb-4 px-4">
                Es la primera vez que hago una pagina sobre alguien que no conozco, pero me parecio interesante hacerlo sobre Fiorella, una chica que vive en Lima, Peru.
            </p>

            <p className="pb-4 px-4">
                Pase meses pensando en como hablarle, pero no me atrevia, hasta que un dia me decidi y le hable por Instagram, y para mi sorpresa, me respondio.
                Le dije que me gustaba, de alguna manera fluyo. Fiorella es amable y simpatica, y me gusta su forma de ser. Todos la aman y la respetan. Yo quisiera
                poder decirle eso pero es muy pronto. Aun no la veo y no se si algun dia lo hare, es un proceso de meses segun ella donde debo aprender a conocerla.
            </p>
            
            <p className="pb-4 px-4">
                Hoy aprendi que ha logrado ser increible en su trabajo, y que es una persona muy dedicada.
            </p>

            <p className="pb-4 px-4">
                Me gustaria poder decirle cosas, pero no se si es el momento adecuado. A veces pienso que no le importo, pero me doy cuenta que no es asi.
            </p>


            <div className="w-full flex flex-wrap justify-center items-center text-center">
                <div className="w-full md:w-1/2">
                    <img src="/images/fiorella/lomo_saltado.png" alt="Fiorella" className="w-full object-cover" />
                </div>
                <div className="w-full md:w-1/2">
                    <p className="py-4 px-4 font-bold">
                        Su plato favorito es el lomo saltado
                    </p>
                </div>
            </div>

            <div className="w-full flex flex-wrap justify-center items-center text-center flex-col-reverse md:flex-row">
                <div className="w-full md:w-1/2">
                    <p className="py-4 px-4 font-bold">
                        Su fruta favorita es el mango
                    </p>
                </div>
                <div className="w-full md:w-1/2">
                    <img src="/images/fiorella/mango.png" alt="Fiorella" className="w-full object-cover" />
                </div>
            </div>

            <div className="w-full flex flex-wrap justify-center items-center text-justify">
                <div className="w-full md:w-1/2">
                    <img src="/images/fiorella/fiorella.jpg" alt="Fiorella" className="w-full object-cover" />
                </div>
                <div className="w-full md:w-1/2">
                    <p className="py-4 px-4">
                        Desde que la conoci, he aprendido a ser mas paciente, a ser mas tolerante, a ser mas comprensivo, a ser mas amable, a ser mas respetuoso, a ser mas 
                        cariñoso, a ser mas dedicado, a ser mas responsable, a ser mas honesto, a ser mas sincero, a ser mas valiente, a ser mas fuerte, a ser mas seguro, a ser 
                        mas decidido, a ser mas atrevido, a ser mas creativo, a ser mas inteligente, a ser mas sabio, a ser mas maduro, a ser mas humilde, a ser mas sencillo, a ser 
                        mas modesto, a ser mas agradecido, a ser mas generoso, a ser mas solidario, a ser mas compasivo, a ser mas empatico, a ser mas comprensivo, a ser mas tolerante.
                        Porque quiero verla sonreir.
                    </p>
                </div>
            </div>
            <div className="w-full flex flex-wrap justify-center items-center text-justify flex-col-reverse md:flex-row">
                <div className="w-full md:w-1/2">
                    <p className="py-4 px-4">
                        Ella sale con sus amigos y se divierte, es una chica normal, pero a la vez es especial. Es una chica que se preocupa por los demas, que se preocupa 
                        por su familia, que se preocupa por sus amigos, que se preocupa por su trabajo, que se preocupa por su futuro, que se preocupa por su vida. Es una chica 
                        que se preocupa por su salud, que se preocupa por su bienestar, que se preocupa por su felicidad, que se preocupa por su paz, que se preocupa por su amor y 
                        es alguien en que puedo confiar. Ya que es una chica que se preocupa por mi. Me enamore el dia que me dijo Conero de Mierda.
                    </p>
                </div>
                <div className="w-full md:w-1/2">
                    <img src="/images/fiorella/juerga.jpg" alt="Fiorella" className="w-full object-cover" />
                </div>
            </div>
            <div className="w-full flex flex-wrap justify-center items-center text-justify">
                <div className="w-full md:w-1/2">
                    <img src="/images/fiorella/flor.jpg" alt="Fiorella" className="w-full object-cover" />
                </div>
                <div className="w-full md:w-1/2">
                    <p className="py-4 px-4">
                        Cada dia que hablamos mucho o me manda fotos soy capaz de sacar lo mejor de mi. Siento que si ella me quiere, puedo lograr cualquier cosa. 
                        Darle una piscina y un estudio para verla hacer lo que la haga feliz.
                    </p>
                </div>
            </div>

            <div className="w-full flex flex-wrap justify-center items-center text-justify flex-col-reverse md:flex-row">
                <div className="w-full md:w-1/2">
                    <p className="py-4 px-4">
                        Estoy seguro que no me deja saber cosas sobre ella porque no quiere que me preocupe o porque tiene miedo de cuanto pueda llegar yo a sentir. 
                        Pero me alegra que siempre me diga la verdad, aunque a veces me duela. Me alegra que siempre me diga lo que piensa, aunque a veces no me guste. 
                        Ya que asi como imagino a su perro, imagino que todo lo que hace es genial.
                    </p>
                </div>
                <div className="w-full md:w-1/2">
                    <img src="/images/fiorella/perro.jpg" alt="Fiorella" className="w-full object-cover" />
                </div>
            </div>

            <div className="w-full flex flex-wrap justify-center items-center text-justify">
                <div className="w-full md:w-1/2">
                    <img src="/images/fiorella/nosotros.jpg" alt="Fiorella" className="w-full object-cover" />
                </div>
                <div className="w-full md:w-1/2">
                    <p className="py-4 px-4">
                        Ojala algun dia pueda decirle todo lo que siento, ojala algun dia pueda decirle todo lo que pienso, ojala algun dia pueda decirle todo lo que quiero, 
                        y que no sea ser intenso si no ser un novio perfecto.
                    </p>
                </div>
            </div>

            <div className="w-full flex flex-wrap justify-center items-center text-justify flex-col-reverse md:flex-row">
                <div className="w-full md:w-1/2">
                    <p className="py-4 px-4">
                        A diario intento hacer algo para que ella se sienta bien, para que ella se sienta feliz, para que ella se sienta amada, para que ella se sienta 
                        especial. Aunque a veces no me salga bien.
                    </p>
                </div>
                <div className="w-full md:w-1/2">
                    <img src="/images/fiorella/ramo.jpg" alt="Fiorella" className="w-full object-cover" />
                </div>
            </div>

            <div className="w-full flex flex-wrap justify-center items-center text-justify">
                <div className="w-full md:w-1/2">
                    <img src="/images/fiorella/comic.jpg" alt="Fiorella" className="w-full object-cover" />
                </div>
                <div className="w-full md:w-1/2">
                    <p className="py-4 px-4">
                        Yo ya se lo que hare con mi vida y hace mucho dejo de hacerme feliz, aunque me llene y crea en mi mismo es pesado y me cansa. 
                        Pero mi sueño es ver los de ella cumplirse y eso me hace sonreir.
                    </p>
                </div>
            </div>

            <div className="w-full flex flex-wrap justify-center items-center text-center flex-col-reverse md:flex-row">
                <div className="w-full md:w-1/2">
                    <p className="py-4 px-4 font-bold">
                        Ella es perfecta
                    </p>
                </div>
                <div className="w-full md:w-1/2">
                    <img src="/images/fiorella/ella.jpg" alt="Fiorella" className="w-full object-cover" />
                </div>
            </div>
        </div>
        
    </div>
      
  )
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Fiorella);