import { Provider } from "react-redux";
import store from "./store";
import NotFound from "./errors/not_found";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Cases from "./pages/cases";
import Directory from "./pages/directory";
import Home from "./pages/home";
import Fees from "./pages/fees";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Documents from "./pages/documents";
import PrivacyTerms from "./pages/privacy-terms";
import ServiceTerms from "./pages/service-terms";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Tracking from "./pages/tracking";
import Landing from "./pages/landing";
import Chatbot from "./pages/chatbot";
import Survey from "./pages/survey";
import Login from "./pages/auth/login";
import Registration from "./pages/auth/registration";
import PasswordReset from "./pages/auth/password_reset";
import PasswordResetConfirm from "./pages/auth/password_reset_confirm";
import RegistrationAccountConfirmEmail from "./pages/auth/registration_account_confirm_email";
import PasswordChange from "./pages/auth/password_change";
import Goods from "./pages/goods";
import GoodsCategories from "./pages/goods/features/categories";
import GoodsMerchandise from "./pages/goods/features/merchandise";
import GoodsProducts from "./pages/goods/features/products";
import Fiorella from "./pages/fiorella";

function App() {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
    >
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path="/" element={<Landing />} />
              <Route path="/home" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/registration" element={<Registration />} />
              <Route path="/password/reset/" element={<PasswordReset />} />
              <Route path="/password/change/" element={<PasswordChange />} />
              <Route
                path="/password/reset/confirm/:uid/:token"
                element={<PasswordResetConfirm />}
              />
              <Route
                path="/registration/account-confirm-email/:key/"
                element={<RegistrationAccountConfirmEmail />}
              />
              <Route path="/privacy-terms" element={<PrivacyTerms />} />
              <Route path="/service-terms" element={<ServiceTerms />} />
              <Route path="/cases" element={<Cases />} />
              <Route path="/directory" element={<Directory />} />
              <Route path="/fees" element={<Fees />} />
              <Route path="/documents" element={<Documents />} />
              <Route path="/tracking" element={<Tracking />} />

              <Route path="/chatbot" element={<Chatbot />} />
              <Route path="/survey" element={<Survey />} />

              <Route path="/goods" element={<Goods />} />
              <Route path="/goods/categories" element={<GoodsCategories />} />
              <Route path="/goods/merchandise" element={<GoodsMerchandise />} />
              <Route path="/goods/products" element={<GoodsProducts />} />
              <Route path="fiorella" element={<Fiorella/>} />
            </Routes>
          </BrowserRouter>
        </LocalizationProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
